<div class="login-page">
    <div class="flexbox-parent">
        <div>
            <form>
                <mat-card class="login-card">
                    <mat-card-title>Login</mat-card-title>
                    <br />
                    <mat-card-content>
                        <div class="container">
                            <div class="row">
                                <mat-label>Email</mat-label>
                                <mat-form-field>
                                    <input matInput [(ngModel)]="username" name="username" required>
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-label>Password</mat-label>
                                <mat-form-field>
                                    <input matInput [(ngModel)]="password" type="password" name="password" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-button style="color: #ffffff; background-color: #77ba2b; width: 100%;"
                            (click)="login()" color="primary">LOGIN</button>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
    </div>
</div>