export class IdeaStateConstants {
    public static submitted: string = 'submitted';
    public static inreview: string = 'inreview';
    public static approved: string = 'approved';
    public static declined: string = 'declined';
    public static implemented: string = 'implemented';


    public static getTranslation(state: string): string {
        if (state == this.submitted) {
            return 'Eingereicht';
        } else if (state == this.inreview) {
            return 'In Prüfung';
        } else if (state == this.approved) {
            return 'Akzeptiert';
        } else if (state == this.declined) {
            return 'Abgelehnt';
        } else if (state == this.implemented) {
            return 'Umgesetzt';
        }
    }

    public static getIdeaStates(): string[] {
        return [this.submitted, this.inreview, this.approved, this.declined, this.implemented];
    }

}