<div class="dashboard-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start">

    <div fxFlex="70%" fxLayout="column" class="dashboard-charts dashboard-padding">
        <div fxFlex="50%">
            <div class="dashboard-chart">
                <div class="dashboard-headline">IDEEN im letzten Monat</div>
                <kendo-chart *ngIf="chartDataMonth">
                    <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="chartDataMonth.categories">
                            <kendo-chart-category-axis-item-labels>
                            </kendo-chart-category-axis-item-labels>
                        </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                        <kendo-chart-series-item *ngFor="let series of chartDataMonth.series" type="line" opacity="0.8"
                            [color]="series.color" [data]="series.data">
                        </kendo-chart-series-item>
                        <kendo-chart-series-item *ngFor="let series of chartDataMonth.series" type="area" opacity="0.8"
                            [color]="series.color" [data]="series.data">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
        <div fxFlex="50%">
            <div class="dashboard-chart">
                <h2 class="dashboard-headline">IDEEN im letzten Jahr</h2>
                <kendo-chart *ngIf="chartDataYear">
                    <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [categories]="chartDataYear.categories">
                            <kendo-chart-category-axis-item-labels>
                            </kendo-chart-category-axis-item-labels>
                        </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                        <kendo-chart-series-item *ngFor="let series of chartDataYear.series" type="area" opacity="0.8" 
                            [color]="series.color" [data]="series.data">
                        </kendo-chart-series-item>
                        <kendo-chart-series-item *ngFor="let series of chartDataYear.series" type="line" opacity="0.8" 
                            [color]="series.color" [data]="series.data">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>
    <div fxFlex="30%" fxFlex.lt-md="100%" fxLayout="column">
        <div fxFlex="35%" fxLayout="row">
            <div fxFlex="50%" class="dashboard-ideas-accepted dashboard-padding">
                <div class="dashboard-headline">IDEEN<br>angenommen</div>
                <div class="dasboard-ideas-statistic" fxLayout="column" fxLayoutAlign="center center">
                    <div class="dashboard-ideas-accepted-number">{{ideasImplemented}}</div>
                    <div class="dashboard-ideas-icon dashboard-ideas-accepted-icon"></div>
                </div>
            </div>
            <div fxFlex="50%" class="dashboard-ideas-declined dashboard-padding">
                <div class="dashboard-headline">IDEEN<br>abgelehnt</div>
                <div class="dasboard-ideas-statistic" fxLayout="column" fxLayoutAlign="center center">
                    <div class="dashboard-ideas-declined-number">{{ideasDeclined}}</div>
                    <div class="dashboard-ideas-icon dashboard-ideas-declined-icon"></div>
                </div>
            </div>
        </div>
        <div fxFlex="65%" class="dashboard-status-overview dashboard-padding">
            <div class="dashboard-headline">Status-Übersicht</div>
            <table class="dashboard-statuses">
                <tr>
                    <td class="dashboard-status-text">Eingegangen</td>
                    <td class="dashboard-status-number">{{ideasSubmitted}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-submitted"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">In Prüfung</td>
                    <td class="dashboard-status-number">{{ideasInreview}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-inreview"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Geprüft und zur Realisierung vorgemerkt</td>
                    <td class="dashboard-status-number">{{ideasApproved}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-approved"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Geprüft und abgelehnt</td>
                    <td class="dashboard-status-number">{{ideasDeclined}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-declined"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Umgesetzt</td>
                    <td class="dashboard-status-number">{{ideasImplemented}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-implemented"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Zur Prämierung eingereicht</td>
                    <td class="dashboard-status-number">{{ideasAwardProposed}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-award-proposed"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Zur Prämierung eingereicht und prämiert</td>
                    <td class="dashboard-status-number">{{ideasAwardConfirmed}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-award-confirmed"></span></td>
                </tr>
                <tr>
                    <td class="dashboard-status-text">Zur Prämierung eingereicht und nicht prämiert</td>
                    <td class="dashboard-status-number">{{ideasAwardDeclined}}</td>
                    <td><span class="dasboard-status-icon dasboard-status-icon-award-declined"></span></td>
                </tr>
            </table>
        </div>
    </div>
</div>