import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IdeaService } from '../services/idea.service';
import { Observable, Subscription } from 'rxjs';
import { Idea } from '../idea/idea';
import { ActivatedRoute, Router, Route, ChildActivationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { IdeaState } from '../idea/idea-state';
import { IdeaHelper } from '../idea/idea-helper';
import { IdeaStateConstants } from '../idea/idea-state-constants';
import { IdeaListItem } from './idea-listitem'
import { IdeaFilterItem} from './idea-filteritem'
import { process, State, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FilterService } from '@progress/kendo-angular-grid';

const flatten = filter => {
  const filters = (filter || {}).filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-ideas',
  templateUrl: './ideas.component.html',
  styleUrls: ['./ideas.component.scss']
})

export class IdeasComponent implements OnInit, OnDestroy {

  public ideas: Idea[] = [];
  private ideasSubscription: Subscription;
  public mySelection: IdeaListItem[] = [];
  public filter: CompositeFilterDescriptor;
  public ideaStates: IdeaFilterItem[] = IdeaStateConstants.getIdeaStates().map(state => {
    return new IdeaFilterItem(
      state,
      IdeaStateConstants.getTranslation(state)
    );
  });
  private ideaStateFilter: any[] = [];

  public state: State = {
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(private ideaService: IdeaService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const user = await this.userService.getCurrentUser();
    this.ideasSubscription = this.ideaService
      .getIdeasForOrganization(user.organizationId)
      .pipe(map(ideas => _.sortBy(ideas, idea => idea.createdAt).reverse()))
      .pipe(map(ideas => ideas.map(idea => {
        return new IdeaListItem(
          idea.id,
          idea.userId,
          idea.userName,
          idea.subject,
          idea.createdAt,
          this.getLatestState(idea.states),
          idea.awardStates,
          idea.organizationId,
          idea.productId)
      })))
      .subscribe(
        ideas => {
          this.ideas = ideas;
          this.gridData = process(ideas, this.state)
        }
      );
  }

  ngOnDestroy() {
    if (this.ideasSubscription) {
      this.ideasSubscription.unsubscribe();
    }
  }

  public onSelectedIdeaChanged(event) {
    const selection = event as Array<string>;
    if (0 < selection.length) {
      const itemId = selection[selection.length - 1];
      this.router.navigate(['/ideas', itemId]);
    }
  }

  public gridData: GridDataResult = process(this.ideas, this.state);

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.ideas, this.state);
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.state.filter = filter;
    this.gridData = process(this.ideas, this.state);
    //this.gridData = filterBy(sampleProducts, filter);
  }

  public ideaStateFilterChange(values: any[], filterService: FilterService): void {
    filterService.filter({
      filters: values.map(value => ({
        field: 'state',
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  public ideaStateFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
    this.ideaStateFilter.splice(
      0, this.ideaStateFilter.length,
      ...flatten(filter).map(({ value }) => value)
    );
    return this.ideaStateFilter;
    //return this.state.filter.filters;
  }

  public getLatestState(ideaStates: IdeaState[]): string {
    return IdeaHelper.getLatestState(ideaStates);
  }
}
