import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Organization } from '../organization/organization';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class OrganizationService {

    constructor(private db: AngularFirestore) { }

    private getOrganizationsInternal(queryFn?: QueryFn): Observable<Organization[]> {
        return this.db.collection<Organization>('organizations', queryFn).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Organization;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ));
      }

      public getOrganizations() : Observable<Organization[]>{
          return this.getOrganizationsInternal();
      }
  }