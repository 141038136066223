<ng-container *ngIf="idea">
    <div class="idea-detail"
        fxLayout="row"
        fxLayoutAlign="flex-start">

        <div fxFlex="100" *ngIf="user" class="idea-chat-container">
            <div class="idea-chat">
                <kendo-chat
                [messages]="messages$ | async"
                [user]="user"
                (sendMessage)="sendMessage($event)">
                <ng-template kendoChatAttachmentTemplate let-attachment>
                  <div class="k-card">
                    <a [href]="attachment.content">
                      <img [src]="attachment.content" class="message-attachment" />
                    </a>
                  </div>
                </ng-template>
            </kendo-chat>
            </div>
        </div>

        <div fxFlex="250px" *ngIf="idea" class="idea-panel">
            <div class="idea-title">{{ idea.subject}}</div>
            <div class="idea-text" style="margin-top: 8px;">
                <span>Eingereicht am: </span>
                <span>{{idea.createdAt.toDate() | date:'dd.MM.y'}}</span>
            </div>
            <h5>IDEEN Status</h5>
            <form>
                <mat-form-field class="idea-status-form-field">
                    <mat-label>Status</mat-label>
                    <mat-select [value]="ideaState" name="Status" (selectionChange)="onIdeaStateSelectionChanged($event)">
                        <mat-option *ngFor="let state of getIdeaStates()" [value]="state">
                            {{ getIdeaStateTranslation(state) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="idea-status-form-field">
                    <mat-label>Prämierung</mat-label>
                    <mat-select [(ngModel)]="ideaAwardState" name="Prämierung" (selectionChange)="onIdeaAwardStateSelectionChanged($event)">
                        <mat-option *ngFor="let state of getIdeaAwardStates()" [value]="state">
                            {{ getIdeaAwardStateTranslation(state) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <!-- organization selector -->
            <div>
                <h5>Organization</h5>
                <kendo-dropdownlist
                    style="width: 100%;"
                    [data]="organizations"
                    [value]="organization"
                    [textField]="'name'"
                    [valueField]="'id'"
                    (valueChange)="organizationSelectionChange($event)">
                </kendo-dropdownlist>
                <button 
                    kendoButton 
                    class="org-button"
                    [disabled]="organizationUnchanged"
                    (click)="onChangeOrganization()">
                    Change Organization
                </button>
            </div>
        </div>
    </div>
</ng-container>