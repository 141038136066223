export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCq63V8se2M3Z404fvz_RDumqVbHB9Zwww",
    authDomain: "improvix-prod.firebaseapp.com",
    databaseURL: "https://improvix-prod.firebaseio.com",
    projectId: "improvix-prod",
    storageBucket: "improvix-prod.appspot.com",
    messagingSenderId: "164033576891",
    appId: "1:164033576891:web:4023b4cc7c542148518e9e"
  }
};
