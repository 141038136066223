import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RedirectAuthGuard } from './guards/redirect-auth.guard';
import { IdeasComponent } from './ideas/ideas.component';
import { IdeaDetailComponent } from './idea-detail/idea-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [RedirectAuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RedirectAuthGuard] },
  { path: 'ideas', component: IdeasComponent, canActivate: [RedirectAuthGuard], 
    children: [
      { path: ':id', component: IdeaDetailComponent}
    ]
  },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
