import { IdeaState } from '../idea/idea-state';

export class IdeaListItem {
    id: string;
    userId: string;
    userName: string;
    organizationId?: string;
    productId?: string;
    subject: string;
    createdAt: Date;
    state: string;
    awardStates: IdeaState[];

    public constructor(
        id: string,
        userId: string,
        userName: string,        
        subject: string,
        createdAt: any,
        state: string,
        awardStates: IdeaState[],
        organizationId?: string,
        productId?: string) {
            this.id = id;
            this.userId = userId;
            this.userName = userName;
            this.organizationId = organizationId;
            this.productId = productId;
            this.subject = subject;
            this.createdAt = createdAt.toDate();
            this.state = state;
            this.awardStates = awardStates;
    }
}