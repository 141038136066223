import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Subscription, Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Idea } from '../idea/idea';
import { IdeaService } from '../services/idea.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  public user: firebase.User;
  public displayName: string;

  private userSubscription: Subscription;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private ideaService: IdeaService
  ) { }

  async ngOnInit() {
    this.userSubscription = this.loginService.getLoggedInUser()
      .subscribe(async user => {
        this.user = user;
        if (!user) {
          return;
        }
        let name = user.displayName || user.email;
        const userInfo = await this.userService.getUser(user.uid);
        if (userInfo) {
          name = userInfo.firstName + ' ' + userInfo.lastName;
        }
        this.displayName = name;
      });

  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public logout() {
    this.loginService.logout();
  }

}
