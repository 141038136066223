export class IdeaAwardStateConstants {
    public static proposed: string = 'proposed';
    public static confirmed: string = 'confirmed';
    public static declined: string = 'declined';
    public static none: string = 'none';

    public static getTranslation(state: string): string {
        if (state == this.proposed) {
            return 'Vorgeschlagen';
        } else if (state == this.confirmed) {
            return 'Angenommen';
        } else if (state == this.declined) {
            return 'Abgelehnt';
        } else if (state === this.none) {
          return 'Nicht eingereicht'
        }
    }

    public static getIdeaAwardStates(): string[] {
        return [this.none, this.proposed, this.confirmed, this.declined];
    }
}