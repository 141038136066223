import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from './../services/login.service';
import { SnackService } from '../services/snack.service';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username: string;

  public password: string;

  constructor(private loginService: LoginService,
              private snacService: SnackService,
              private router: Router) { }

  ngOnInit() {
  }

  public async login(): Promise<void> {
    try {
      const userCredentiol = await this.loginService.login(this.username, this.password);
      this.router.navigate(['/dashboard']);
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === 'auth/wrong-password') {
        this.snacService.showMessage('Wrong password.');
      } else {
        this.snacService.showMessage(errorMessage);
      }
      console.log(error);
    } 
  }

}
