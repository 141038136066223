import { Component, OnInit, OnDestroy, ViewEncapsulation, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdeaService } from '../services/idea.service';
import { Observable, Subscription } from 'rxjs';
import { Idea } from '../idea/idea';
import { Message } from '../message/message';
import { SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import { Message as KendoMessage, User as KendoUser, Attachment as KendoAttachment } from '@progress/kendo-angular-conversational-ui';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';
import { OrganizationService } from '../services/organization.service';
import { User } from '../user/user';
import { IdeaStateConstants } from '../idea/idea-state-constants';
import { IdeaHelper } from '../idea/idea-helper';
import { IdeaAwardStateConstants } from '../idea/idea-award-state-constants';
import { MatSelectChange } from '@angular/material/select';
import { IdeaState } from '../idea/idea-state';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { Organization } from '../organization/organization';


@Component({
  selector: 'app-idea-detail',
  templateUrl: './idea-detail.component.html',
  styleUrls: ['./idea-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdeaDetailComponent implements OnInit, OnDestroy {

  private ideaId: string;
  private currentUser: User;
  private ideaSubscription: Subscription;
  private organizationSubscription: Subscription;

  public idea: Idea;
  public messages$: Observable<KendoMessage[]>;
  public user: KendoUser;
  public ideaState: string;
  public ideaAwardState: string;
  public organizations: Organization[] = [];
  public organization: Organization;
  public organizationUnchanged: boolean = true;

  constructor(private route: ActivatedRoute,
    private ideaService: IdeaService,
    private userService: UserService,
    private loginService: LoginService,
    private organizationService: OrganizationService,
    private httpClient: HttpClient) { }

  private loadIdea(idea: Idea) {
    this.idea = idea;
    this.idea.id = this.ideaId;
    this.ideaState = IdeaHelper.getLatestState(idea.states);
    this.ideaAwardState = IdeaHelper.getLatestState(idea.awardStates);

    this.organizationSubscription = this.organizationService.getOrganizations().subscribe(orgs => {
      this.organizations = orgs;
      this.organization = orgs.find(org => org.id == this.idea.organizationId)
      this.organizationUnchanged = true;
    });

    this.messages$ = this.ideaService.getMessages(this.ideaId)
          .pipe(map(messages => messages.map((message: Message) => {

            let attachments: KendoAttachment[] = undefined;
            if (message.attachment) {
              attachments = [{ content: message.attachment.url, contentType: 'image/jpeg' }];
            }

            return {
              author: { id: message.userId, name: message.userName },
              text: message.content,
              name: message.userName,
              attachments,
              timestamp: message.createdAt ? message.createdAt.toDate() : null
            }
          })
          ));
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.ideaId = params['id'];
      if (!!this.ideaId) {
        this.ideaSubscription = this.ideaService.getIdea(this.ideaId).subscribe(idea => {
          this.loadIdea(idea);
        });
      }
    });
    this.currentUser = await this.userService.getCurrentUser();
    this.user = { id: this.currentUser.userId };
  }

  ngOnDestroy() {
    if (this.ideaSubscription) {
      this.ideaSubscription.unsubscribe();
    }

    if (this.organizationSubscription) {
      this.organizationSubscription.unsubscribe();
    }
  }

  public async sendMessage(e: SendMessageEvent): Promise<void> {
    await this.ideaService.sendMessage(this.ideaId,
      {
        content: e.message.text,
        createdAt: new Date(),
        userName: this.currentUser.firstName + ' ' + this.currentUser.lastName,
        userId: e.message.author.id
      });
  }

  public async onIdeaStateSelectionChanged(event: MatSelectChange): Promise<void> {
    const state = event.value;
    const newIdeaState: IdeaState = { userId: this.currentUser.userId, state, createdAt: new Date() };
    if (!this.idea.states) {
      this.idea.states = [];
    }
    this.idea.states.push(newIdeaState);
    await this.ideaService.updateIdea(this.idea);
  }

  public async onIdeaAwardStateSelectionChanged(event: MatSelectChange): Promise<void> {
    const state = event.value;
    const newIdeaState: IdeaState = { userId: this.currentUser.userId, state, createdAt: new Date() };
    if (!this.idea.awardStates) {
      this.idea.awardStates = [];
    }
    this.idea.awardStates.push(newIdeaState);
    await this.ideaService.updateIdea(this.idea);
  }

  public getIdeaStates(): string[] {
    return IdeaStateConstants.getIdeaStates();
  }

  public getIdeaStateTranslation(state: string): string {
    return IdeaStateConstants.getTranslation(state);
  }

  public getIdeaAwardStates(): string[] {
    return IdeaAwardStateConstants.getIdeaAwardStates();
  }

  public getIdeaAwardStateTranslation(state: string): string {
    return IdeaAwardStateConstants.getTranslation(state);
  }

  public organizationSelectionChange(value: any): void {
    const org = value as Organization;
    if (this.idea.organizationId == org.id) {
      this.organizationUnchanged = true;
    }
    else {
      this.organizationUnchanged = false;
    }

    this.organization = org;
  }

  public onChangeOrganization() {
    const oId = this.idea.organizationId;
    this.idea.organizationId = this.organization.id;
    this.ideaService.updateIdea(this.idea);

    this.ideaService.getLatestIdeaForOrganization(oId).subscribe(idea => {
      this.loadIdea(idea);
    });
  }
}
