import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdeaService } from '../services/idea.service';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Idea } from '../idea/idea';
import * as moment from 'moment';
import { IdeaStateConstants } from '../idea/idea-state-constants';
import { ChartData } from './chart-data';
import { IdeaState } from '../idea/idea-state';
import { IdeaAwardStateConstants } from '../idea/idea-award-state-constants';
import { UserService } from '../services/user.service';
import { IdeaHelper } from '../idea/idea-helper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public chartDataMonth: ChartData;
  public chartDataYear: ChartData;

  public ideasSubmitted = 0;
  public ideasInreview = 0;
  public ideasApproved = 0;
  public ideasDeclined = 0;
  public ideasImplemented = 0;
  public ideasAwardProposed = 0;
  public ideasAwardConfirmed = 0;
  public ideasAwardDeclined = 0;

  private ideaSupscription: Subscription;

  private chartSeries: any = {};

  constructor(private ideaService: IdeaService,
              private userService: UserService) { 
    this.chartSeries[IdeaStateConstants.submitted] = '#2b77ba';
    this.chartSeries[IdeaStateConstants.implemented] = 'green';
    this.chartSeries[IdeaStateConstants.declined] = 'red';
    this.chartSeries[IdeaStateConstants.approved] = 'yellow';
    this.chartSeries[IdeaStateConstants.inreview] = 'orange';
  }

  async ngOnInit() {
    const user =  await this.userService.getCurrentUser();
    this.chartDataMonth = this.mapIdeaToChartData([], 30, 'day');
    this.chartDataYear = this.mapIdeaToChartData([], 365, 'month');
    this.ideaSupscription = this.ideaService.getIdeasForOrganization(user.organizationId).subscribe(ideas => {
      this.ideasSubmitted = this.countByState(ideas, IdeaStateConstants.submitted);
      this.ideasInreview = this.countByState(ideas, IdeaStateConstants.inreview);
      this.ideasApproved = this.countByState(ideas, IdeaStateConstants.approved);
      this.ideasDeclined = this.countByState(ideas, IdeaStateConstants.declined);
      this.ideasImplemented = this.countByState(ideas, IdeaStateConstants.implemented);
      this.ideasAwardProposed = this.countByAwardState(ideas, IdeaAwardStateConstants.proposed);
      this.ideasAwardConfirmed = this.countByAwardState(ideas, IdeaAwardStateConstants.confirmed);
      this.ideasAwardDeclined = this.countByAwardState(ideas, IdeaAwardStateConstants.declined);
      this.chartDataMonth = this.mapIdeaToChartData(ideas, 30, 'day');
      this.chartDataYear = this.mapIdeaToChartData(ideas, 365, 'month');
    });
  }

  ngOnDestroy() {
    if (this.ideaSupscription) {
      this.ideaSupscription.unsubscribe();
    }
  }

  private filterByState(ideas: Idea[], state: string): Idea[] {
    return _.filter(ideas, idea => IdeaHelper.getLatestState(idea.states) === state);
  }

  private countByState(ideas: Idea[], state: string): number {
    return this.filterByState(ideas, state).length;
  }

  private filterByAwardState(ideas: Idea[], state: string): Idea[] {
    return _.filter(ideas, idea => IdeaHelper.getLatestState(idea.awardStates) === state);
  }

  private countByAwardState(ideas: Idea[], state: string): number {
    return this.filterByAwardState(ideas, state).length;
  }

  private mapIdeaToChartData(ideas: Idea[], numberOfDays: number, base: 'day' | 'month'): ChartData {
    var today = new Date()
    const days = numberOfDays;
    var priorDate = new Date().setDate(today.getDate() - days);
    const format = base === 'day' ? 'DD.MM.' : 'MMM YY';
    const step = base === 'day' ? 2 : 30;

    const series: {data: number[], color: string}[] = [];
    const dataOfLastPeriod = _.filter(ideas, idea => idea.createdAt && priorDate < idea.createdAt.toDate());

    const groupedByDate = _.countBy(dataOfLastPeriod, idea => moment(idea.createdAt.toDate()).startOf(base).format(format));
    
    const data: number[] = [];
    for (let i = days - 1; i >= 0; i -= step) {
      const date = moment(new Date().setDate(today.getDate() - i)).startOf(base).format(format);
      const d = groupedByDate[date] || 0;
      data.push(d);
    }
    series.push({data, color: '#2b77ba'});
    
    const categories: string[] = [];
    for (let i = days - 1; i >= 0; i -= step) {
      const date = moment(new Date().setDate(today.getDate() - i)).startOf(base).format(format);
      categories.push(date);
    }
    return {categories, series };
  }

}
