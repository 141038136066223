<mat-toolbar
  id="mainToolbar"
  class="main-tool-bar">

  <div class="logo"></div>

  <ng-container *ngIf="user">
    <a [routerLink]="'/dashboard'" class="menu-link" routerLinkActive="active-link">
      <span class="dashboard-menu-icon menu-icon"></span>
      <span fxHide.lt-sm class="menu-text">Dashboard</span>
    </a>
    <a [routerLink]="['/ideas']" class="menu-link" routerLinkActive="active-link">
      <span class="idea-menu-icon menu-icon"></span>
      <span fxHide.lt-sm class="menu-text">IDEEN Box</span>
    </a>
  </ng-container>

  <div class="menu-right">
    <ul class="menu-flex">
      <li *ngIf="user" [matMenuTriggerFor]="userDropDown">
          <a mat-button class="user-menu menu-icon">
          </a>
          <span fxHide.lt-sm class="user-text" *ngIf="displayName">{{ displayName }}</span>
      </li>
    </ul>
  </div>
</mat-toolbar>

<mat-menu
  #userDropDown="matMenu">
  <mat-card>
    <mat-card-header>
      <mat-card-title *ngIf="displayName">{{ displayName }}</mat-card-title>
      <mat-card-subtitle>{{ user?.email }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <button
    mat-menu-item
    (click)="logout()"
    >
    <span>Sign out</span>
  </button>

</mat-menu>