import { Component } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CIPIDEA';
  private userSubscription: Subscription;
  constructor(
    private loginService: LoginService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.userSubscription = this.loginService.getLoggedInUser()
      .subscribe(async user => {
      });

  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
