import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Idea } from '../idea/idea';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { Message } from '../message/message';

@Injectable({
  providedIn: 'root'
})
export class IdeaService {

  constructor(private db: AngularFirestore) { }

  public getIdea(ideaId: string): Observable<Idea> {
    return this.db.doc<Idea>(`ideas/${ideaId}`).valueChanges();
  }

  public updateIdea(idea: Idea): Promise<void> {
    return this.db.doc<Idea>(`ideas/${idea.id}`).update(idea);
  }

  private getIdeasInternal(queryFn?: QueryFn): Observable<Idea[]> {
    return this.db.collection<Idea>('ideas', queryFn).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Idea;
        const id = a.payload.doc.id;
        return { id, ...data };
      })
    ));
  }

  public getIdeasForOrganization(organizationId: string): Observable<Idea[]> {
    if (!organizationId) {
      return of([]);
    }
    const organizationQuery: QueryFn = query => query.where('organizationId', '==', organizationId);
    return this.getIdeasInternal(organizationQuery);
  }

  public getLatestIdeaForOrganization(organizationId: string): Observable<Idea> {
    if (!organizationId) {
      return of(null);
    }
    const organizationQuery: QueryFn = query => query.where('organizationId', '==', organizationId)
      .orderBy('createdAt', 'desc').limit(1);
    return this.getIdeasInternal(organizationQuery).pipe(map(ideas => ideas[0]));;
  }

  public getLatestIdea(): Observable<Idea> {
    const query: QueryFn = query => query
      .orderBy('createdAt', 'desc').limit(1);
    return this.getIdeasInternal(query).pipe(map(ideas => ideas[0]));
  }

  public getIdeas(): Observable<Idea[]> {
    return this.getIdeasInternal();
  }

  public getMessages(ideaId: string): Observable<Message[]> {
    return this.db.collection<Message>(`ideas/${ideaId}/messages`, query => query.orderBy('createdAt')).valueChanges();
  }

  public sendMessage(ideaId: string, message: Message): Promise<DocumentReference> {
    return this.db.collection<Message>(`ideas/${ideaId}/messages`).add(message);
  }
}
