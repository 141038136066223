<div class="idea-panel" fxLayout="row" fxFlexFill fxLayoutAlign="start stretch">
    <div class="idea-list" fxFlex="33">
        <kendo-grid 
            [data]="gridData" 
            [style.height.%]="100" 
            [selectable]=true 
            [filter]="state.filter"
            filterable="menu"
            kendoGridSelectBy="id" 
            (selectedKeysChange)="onSelectedIdeaChanged($event)"
            
            (filterChange)="filterChange($event)">
            <kendo-grid-column 
                field="state" 
                title="" 
                [width]="48">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div>
                        <span class="idea-state {{ dataItem.state}}"></span>
                    </div>
                </ng-template>
                <ng-template kendoGridFilterMenuTemplate
                    let-column="column"
                    let-filter="filter"
                    let-filterService="filterService">
                    <kendo-multiselect
                        style="width:220px"
                        [data]="ideaStates"
                        textField="stateDisplay"
                        valueField="state"
                        [valuePrimitive]="true"
                        [value]="ideaStateFilters(filter)"
                        (valueChange)="ideaStateFilterChange($event, filterService)">
                    </kendo-multiselect>
                </ng-template>
                <!-- <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                    <idea-state-filter
                        [filter]="state.filter"
                        [data]="ideaStates"
                        textField=""
                        valueField="state">
                    </idea-state-filter>
                </ng-template> -->
            </kendo-grid-column>
            <kendo-grid-column 
                class="idea-row" 
                field="createdAt" 
                title="Datum" 
                format="{0:MM/dd/yyyy}" 
                filter="date"
                [width]="92">
            </kendo-grid-column>
            <kendo-grid-column field="userName" title="Name" [width]="120">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell
                      [showOperators]="false"
                      [column]="column"
                      [filter]="state.filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="subject" title="Artikel od. Organisation">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell
                      [showOperators]="false"
                      [column]="column"
                      [filter]="state.filter">
                    </kendo-grid-string-filter-cell>
                  </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>

    <div fxFlex="100">
        <router-outlet></router-outlet>
    </div>
</div>