import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../user/user';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private db: AngularFirestore,
              private loginService: LoginService) { 
  }

  public async getUser(userId: string): Promise<User> {
    if (!userId) {
      return null;
    }
    const users = await this.db.collection<User>('users', query => query.where('userId', '==', userId).limit(1)).valueChanges().pipe(first()).toPromise();
    if (users && users.length === 1) {
      return users[0];
    }
    return null;
  }

  public async getCurrentUser(): Promise<User> {
    const firebaseUser = await this.loginService.getLoggedInUser().pipe(first()).toPromise();
    if (!firebaseUser) {
      return null;
    }
    return await this.getUser(firebaseUser.uid);
  }
}
