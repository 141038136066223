import { IdeaState } from './idea-state';
import * as _ from 'lodash';

export class IdeaHelper {
    public static getLatestState(ideaStates: IdeaState[]): string {
        const lastState = _.last(_.sortBy(ideaStates, state => state.createdAt.toDate()));
        if (lastState) {
          return lastState.state;
        } else {
          return null;
        }
      }
    
}